<template>
  <div id="imported">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Imported</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="has-text-danger" v-if="imported"
            >This vehicle was imported</span
          >
          <span class="has-text-success" v-else>Not imported</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoCheckImported',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    imported() {
      return this.data.imported
    }
  }
}
</script>
